import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styles from './article-preview.module.css'

export default ({ article }) => (
  <div className={styles.preview}>
    <h3 className={styles.previewTitle}>
      <Link to={`/blog/${article.slug}`}>{article.title}</Link>
    </h3>
    <small>
      {article.publishDate}
      {article.tags &&
        article.tags.map((tag) => (
          <span className={styles.tag} key={tag}>
            <Link to={`/tags/${tag}`}>#{tag} </Link>
          </span>
        ))}
    </small>
    <p
      dangerouslySetInnerHTML={{
        __html: article.description.childMarkdownRemark.html,
      }}
    />
  </div>
)
